import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom'
import { GetADToken } from '../Utilities/TokenManager';
import { APIPost } from '../API/APIRequest'
import { ErrorBox } from '../Utilities/ErrorBox'
import { GenericErrorBox } from '../UI/ErrorBox/GenericErrorBox';

export function LogWebActivity(props) {
    
    const [logged, setLogged] = useState(false);
    const [errors, setErrors] = useState([]);
    const { environment } = useParams();
    const {actionName, isGuest, guestSession} = props;
    const fid= sessionStorage.getItem("fid");
    const adtokenStr = sessionStorage.getItem("adtoken");  //gives you a string
    const adtokenObj = GetADToken();   //gives you an object

    const BasketItems = () => {
        let items = [];
        let itemsString ="";
        
        //creating a string of basket items 
        if(actionName==="Checkout started" || actionName==="Checkout completed" || actionName==="Checkout restricted"){
            let basket = JSON.parse(sessionStorage.getItem(fid));
            if (basket?.lines) {
                basket.lines.forEach((variant, id) => {
                    items.push(variant?.productvariantid +"," + variant?.productvariantname)
                })
             }}
             
        itemsString = items?.join(";");
        return itemsString;
    }

    useEffect(() => {
        
        //for view page web activity log
        if ((adtokenObj && !isGuest) || (isGuest && guestSession)) {
            logWebActivity();
        }

    }, [])

    useEffect(() => {

        //for checkout started, checkout completed, discount code applied logs
        if (actionName) {
            logWebActivity(actionName);
        }

    }, [actionName])


    const logWebActivity = (actionNameValue) => {
        if (!logged || (actionNameValue)) {
            const data = new FormData();
            
            if(actionNameValue==="Checkout started" || actionNameValue==="Checkout completed" || actionNameValue==="Checkout restricted" ){

                data.append('item', BasketItems());
                data.append('actionName', actionNameValue )     
               
             }else if(actionNameValue==="Order Discount Code"){

                let discountCode=sessionStorage.getItem("discountApplied");   //only in discountFlow
                data.append('item', discountCode);
                data.append('actionName', actionNameValue );
             }
            else{
                data.append('item', window.location.href);
            }
           
            data.append('userToken', adtokenStr);  // ad token object is being passed as a string, needed in all calls
         
            APIPost({
                "controller": "WebActivity",
                "action": "SaveWebActivity",
                "environment": environment,
                "headers": [{
                    "key": "UserToken",
                    "value": adtokenObj?.token ?? ""  // userToken string is being passed
                }],
                data: data
                }).then((response) => {
                if(actionName==="Checkout started"){
                    sessionStorage.setItem("CheckoutStarted", true);    
                    props.setCheckoutStartedFired && props.setCheckoutStartedFired("true");   // for serialising checkout restricted call after checkout started 
                }
                setLogged(true);
            }).
                catch((error) => {
                    var unexpected = [];
                    unexpected.push("error.unexpected");
                    setErrors(unexpected);
            });
           
        }
    }
    return (<GenericErrorBox errors={errors}/>);
}
